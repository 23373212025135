<template>
  <div>
    <app-cells position="between">
      <app-cells :indent="false">
        <h1 v-if="!userIsAdmin" class="title title--theme">Номера</h1>
        <router-link v-if="userIsAdmin" :to="{ name: 'numbers-stat' }" class="btn btn--link">
          <img src="@/assets/img/sidebar/stat.svg" alt="Stat icon">
          <span>Статистика</span>
        </router-link>
      </app-cells>
      <v-select
        v-if="userIsAdmin"
        :reduce="item => item.id"
        :options="delegation_options"
        :filterable="true"
        :clearable="false"
        v-model="user_delegation_id"
        @input="onSetDelegation"
        label="name"
        placeholder="Выберите делегацию"
        class="select select--minw"
      >
        <template #open-indicator>
          <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
            <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
            <path d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z" fill="#fff"></path>
          </svg>
        </template>
        <template #option="option">
          <div class="select__item d-center">{{ option.name }}</div>
        </template>
        <template #selected-option="option">
          <div class="selected d-center">{{ option.name }}</div>
        </template>
        <template #no-options>Ничего не найдено</template>
      </v-select>
    </app-cells>
    <app-cells v-if="!userIsAdmin && delegation_settings.numbers_done" position="start">
      <div class="notice" :class="{'notice--yellow': !delegation_settings.numbers_print, 'notice--green': delegation_settings.numbers_print,}">
        <div class="notice__title">
          <img v-if="!delegation_settings.numbers_print" src="@/assets/img/info-icon-yellow.svg" alt="Info icon">
          <img v-else src="@/assets/img/info-icon-green.svg" alt="Info icon">
          <span>Информация</span>
        </div>
        <p v-if="!delegation_settings.numbers_print" class="notice__text">
          Ваша делегация находится на проверке регламентной службой. Добавление новых номеров - недоступно. После проверки у вас появится возможность скачать сформированную заявку делегации.
        </p>
        <p v-else class="notice__text">
          Ваша делегация проверена регламентной службой. Вы можете скачать заявку делегации.
        </p>
      </div>
    </app-cells>
    <app-cells v-if="!userIsAdmin && (!delegation_settings.numbers_done || delegation_settings.numbers_print)" position="between">
      <app-cells position="start" :indent="false">
        <router-link v-if="!delegation_settings.numbers_done" :to="{ name: 'numbers-create' }" class="btn btn--link btn--link-icon">
          <span>Добавить номер</span>
        </router-link>
        <template v-if="delegation_settings.numbers_print">
          <app-button
            size="link"
            :class="{'btn--loading': download_app}"
            @click="onDownloadApplication"
          >
            <img src="@/assets/img/file/import-icon.svg" alt="Скачать">
            <span>Скачать заявку делегации</span>
          </app-button>
          <app-button
            size="link"
            :class="{'btn--loading': download_app2}"
            @click="onDownloadApplicationZip"
          >
            <img src="@/assets/img/file/import-icon.svg" alt="Скачать">
            <span>Скачать заявки по номерам</span>
          </app-button>
        </template>
      </app-cells>
      <app-button
        v-if="!delegation_settings.numbers_done"
        @click="onShowModal"
        size="small"
      >
        Отправить на проверку
      </app-button>
    </app-cells>
    <app-cells v-if="userIsAdmin" position="start">
      <app-checkbox
        v-model="delegation_settings.numbers_done"
        @change="onChangeDelegationSettings({numbers_done: delegation_settings.numbers_done}, false, true)"
        label="Номера сформированы"
      />
      <app-checkbox
        v-model="delegation_settings.numbers_print"
        @change="onChangeDelegationSettings({numbers_print: delegation_settings.numbers_print}, false)"
        label="Разрешить печать заявки"
      />
      <app-button
        @click="onDownloadApplication"
        size="link"
        :class="{'btn--loading': download_app}"
      >
        <img src="@/assets/img/file/import-icon.svg" alt="Скачать">
        <span>Скачать заявку делегации</span>
      </app-button>
      <app-button
        size="link"
        :class="{'btn--loading': download_app2}"
        @click="onDownloadApplicationZip"
      >
        <img src="@/assets/img/file/import-icon.svg" alt="Скачать">
        <span>Скачать заявки по номерам</span>
      </app-button>
    </app-cells>
    <h2 class="title title--indent title--medium">Музыкальное направление</h2>
    <v-client-table
      @row-click="onRowClick"
      :data="table_music.items"
      :columns="table_music.columns"
      :options="table_music.options"
      class="table-default table-default--dynamic table-default--indent"
    >
      <template #collective_name="props" >
        {{ props.row.collective_name ? props.row.collective_name : props.row.performers }}
      </template>
    </v-client-table>
    <h2 class="title title--indent title--medium">Танцевальное направление</h2>
    <v-client-table
      @row-click="onRowClick"
      :data="table_dance.items"
      :columns="table_dance.columns"
      :options="table_dance.options"
      class="table-default table-default--dynamic table-default--indent"
    >
      <template #collective_name="props" >
        {{ props.row.collective_name ? props.row.collective_name : props.row.performers }}
      </template>
    </v-client-table>
    <h2 class="title title--indent title--medium">Театральное направление</h2>
    <v-client-table
      @row-click="onRowClick"
      :data="table_theatre.items"
      :columns="table_theatre.columns"
      :options="table_theatre.options"
      class="table-default table-default--dynamic table-default--indent"
    >
      <template #collective_name="props" >
        {{ props.row.collective_name ? props.row.collective_name : props.row.performers }}
      </template>
    </v-client-table>
    <h2 class="title title--indent title--medium">Оригинальный жанр</h2>
    <v-client-table
      @row-click="onRowClick"
      :data="table_original.items"
      :columns="table_original.columns"
      :options="table_original.options"
      class="table-default table-default--dynamic table-default--indent"
    >
      <template #collective_name="props" >
        {{ props.row.collective_name ? props.row.collective_name : props.row.performers }}
      </template>
    </v-client-table>
    <h2 class="title title--indent title--medium">Видео</h2>
    <v-client-table
      @row-click="onRowClick"
      :data="table_video.items"
      :columns="table_video.columns"
      :options="table_video.options"
      class="table-default table-default--dynamic table-default--indent"
    />
    <h2 class="title title--indent title--medium">Арт</h2>
    <v-client-table
      @row-click="onRowClick"
      :data="table_art.items"
      :columns="table_art.columns"
      :options="table_art.options"
      class="table-default table-default--dynamic table-default--indent"
    />
    <h2 class="title title--indent title--medium">Научный слэм</h2>
    <v-client-table
      @row-click="onRowClick"
      :data="table_science.items"
      :columns="table_science.columns"
      :options="table_science.options"
      class="table-default table-default--dynamic"
    />
    <modal
      name="send-review-modal"
      :width="750"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
    >
      <div class="modal">
        <div class="modal__title modal__title--center-black">Подтверждение действия</div>
        <p class="modal__text">
          Вы уверены, что хотите отправить список номеров на проверку и завершить формирование
          делегации? Вы не сможете добавлять новые номера. Это действие сможет отменить только регламентная служба
        </p>
        <app-cells position="center">
          <app-button size="small" @click="onChangeDelegationSettings({numbers_done: true}, true)" ref="reviewSubmit">Да</app-button>
          <app-button size="small" @click="onHideModal">Нет</app-button>
        </app-cells>
      </div>
    </modal>
  </div>
</template>

<script>
import {
  getNumbersList,
  getDelegations,
  patchUser,
  getFile,
  getDelegationSettings,
  patchDelegationSettings,
  getNumbersApp,
  getNumbersZip
} from '@/http'

export default {
  name: 'NumbersList',
  data() {
    return {
      table_music: {
        items: [],
        columns: ['id', 'nomination', 'amount', 'number_name', 'collective_name', 'participants_count', 'status.name'],
        options: {
          headings: {
            'id': 'ID',
            'nomination': 'Номинация',
            'amount': 'Форма',
            'number_name': 'Название',
            'collective_name': 'Исполнитель',
            'participants_count': 'Участников',
            'status.name': 'Статус',
          },
          pagination: { show: false },
          sortable: [],
          perPage: 100,
          texts: {
            filter: '',
            filterPlaceholder: 'Поиск по таблице',
            noResults: 'Вы еще не добавили ни одного номера',
            filterBy: '',
            loading: 'Загрузка',
            count: '',
            limit: 'Количество записей на странице:',
            page: 'Страница:'
          },
          rowClassCallback: row => `table-default__row table-default__row--${row.status.code}`,
        },
      },
      table_dance: {
        items: [],
        columns: ['id', 'nomination', 'amount', 'number_name', 'collective_name', 'participants_count', 'status.name'],
        options: {
          headings: {
            'id': 'ID',
            'nomination': 'Номинация',
            'amount': 'Форма',
            'number_name': 'Название',
            'collective_name': 'Исполнитель',
            'participants_count': 'Участников',
            'status.name': 'Статус',
          },
          pagination: { show: false },
          sortable: [],
          perPage: 100,
          texts: {
            filter: '',
            filterPlaceholder: 'Поиск по таблице',
            noResults: 'Вы еще не добавили ни одного номера',
            filterBy: '',
            loading: 'Загрузка',
            count: '',
            limit: 'Количество записей на странице:',
            page: 'Страница:'
          },
          rowClassCallback: row => `table-default__row table-default__row--${row.status.code}`,
        },
      },
      table_theatre: {
        items: [],
        columns: ['id', 'nomination', 'amount', 'number_name', 'collective_name', 'participants_count', 'status.name'],
        options: {
          headings: {
            'id': 'ID',
            'nomination': 'Номинация',
            'amount': 'Форма',
            'number_name': 'Название',
            'collective_name': 'Исполнитель',
            'participants_count': 'Участников',
            'status.name': 'Статус',
          },
          pagination: { show: false },
          sortable: [],
          perPage: 100,
          texts: {
            filter: '',
            filterPlaceholder: 'Поиск по таблице',
            noResults: 'Вы еще не добавили ни одного номера',
            filterBy: '',
            loading: 'Загрузка',
            count: '',
            limit: 'Количество записей на странице:',
            page: 'Страница:'
          },
          rowClassCallback: row => `table-default__row table-default__row--${row.status.code}`,
        },
      },
      table_original: {
        items: [],
        columns: ['id', 'nomination', 'amount', 'number_name', 'collective_name', 'participants_count', 'status.name'],
        options: {
          headings: {
            'id': 'ID',
            'nomination': 'Номинация',
            'amount': 'Форма',
            'number_name': 'Название',
            'collective_name': 'Исполнитель',
            'participants_count': 'Участников',
            'status.name': 'Статус',
          },
          pagination: { show: false },
          sortable: [],
          perPage: 100,
          texts: {
            filter: '',
            filterPlaceholder: 'Поиск по таблице',
            noResults: 'Вы еще не добавили ни одного номера',
            filterBy: '',
            loading: 'Загрузка',
            count: '',
            limit: 'Количество записей на странице:',
            page: 'Страница:'
          },
          rowClassCallback: row => `table-default__row table-default__row--${row.status.code}`,
        },
      },
      table_video: {
        items: [],
        columns: ['id', 'nomination', 'amount', 'number_name', 'participants_count', 'status.name'],
        options: {
          headings: {
            'id': 'ID',
            'nomination': 'Номинация',
            'amount': 'Форма',
            'number_name': 'Название',
            'participants_count': 'Участников',
            'status.name': 'Статус',
          },
          pagination: { show: false },
          sortable: [],
          perPage: 100,
          texts: {
            filter: '',
            filterPlaceholder: 'Поиск по таблице',
            noResults: 'Вы еще не добавили ни одного номера',
            filterBy: '',
            loading: 'Загрузка',
            count: '',
            limit: 'Количество записей на странице:',
            page: 'Страница:'
          },
          rowClassCallback: row => `table-default__row table-default__row--${row.status.code}`,
        },
      },
      table_art: {
        items: [],
        columns: ['id', 'nomination', 'amount', 'number_name', 'status.name'],
        options: {
          headings: {
            'id': 'ID',
            'nomination': 'Номинация',
            'amount': 'Форма',
            'number_name': 'Название',
            'status.name': 'Статус',
          },
          pagination: { show: false },
          sortable: [],
          perPage: 100,
          texts: {
            filter: '',
            filterPlaceholder: 'Поиск по таблице',
            noResults: 'Вы еще не добавили ни одного номера',
            filterBy: '',
            loading: 'Загрузка',
            count: '',
            limit: 'Количество записей на странице:',
            page: 'Страница:'
          },
          rowClassCallback: row => `table-default__row table-default__row--${row.status.code}`,
        },
      },
      table_science: {
        items: [],
        columns: ['id', 'nomination', 'amount', 'number_name', 'status.name'],
        options: {
          headings: {
            'id': 'ID',
            'nomination': 'Номинация',
            'amount': 'Форма',
            'number_name': 'Название',
            'status.name': 'Статус',
          },
          pagination: { show: false },
          sortable: [],
          perPage: 100,
          texts: {
            filter: '',
            filterPlaceholder: 'Поиск по таблице',
            noResults: 'Вы еще не добавили ни одного номера',
            filterBy: '',
            loading: 'Загрузка',
            count: '',
            limit: 'Количество записей на странице:',
            page: 'Страница:'
          },
          rowClassCallback: row => `table-default__row table-default__row--${row.status.code}`,
        },
      },
      delegation_options: [],
      user_delegation_id: null,
      status_options: [],
      status_code: '',
      delegation_settings: {},
      download_app: false,
      download_app2: false,
      delegation_name: '',
    }
  },
  computed: {
    userIsAdmin() {
      return this.$store.state.user_role === 'admin'
    },
    userIsHead() {
      return this.$store.state.user_role === 'head'
    },
  },
  created() {
    this.fetchItems()
    this.fetchDelegationsSettings()
    setTimeout(() => {
      if (this.userIsAdmin) {
        getDelegations()
          .then(response => {
            this.delegation_options = response.data
            this.user_delegation_id = this.$store.state.delegation
            if (this.$route.params.delegation_id) {
              this.user_delegation_id = this.$route.params.delegation_id
              this.onSetDelegation()
            } else {
              this.delegation_name = this.delegation_options.find(item => item.id === this.user_delegation_id).name
            }
          })
      }
    }, 200)
  },
  methods: {
    fetchDelegationsSettings() {
      getDelegationSettings()
        .then(response => {
          this.delegation_settings = response.data
        })
    },
    fetchItems() {
      getNumbersList()
        .then(response => {
          this.table_music.items = response.data[0].items
          this.table_dance.items = response.data[1].items
          this.table_theatre.items = response.data[2].items
          this.table_original.items = response.data[3].items
          this.table_video.items = response.data[4].items
          this.table_art.items = response.data[5].items
          this.table_science.items = response.data[6].items
        })
    },
    onDownloadApplication() {
      this.download_app = true
      getNumbersApp()
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `Заявка на участие - Номера ${this.delegation_name}.pdf`)
          document.body.appendChild(link)
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .finally(() => {
          this.download_app = false
        })
    },
    onDownloadApplicationZip() {
      this.download_app2 = true
      getNumbersZip()
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `Заявки на участие - Номера ${this.delegation_name}.zip`)
          document.body.appendChild(link)
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .finally(() => {
          this.download_app2 = false
        })
    },
    onShowModal() {
      this.$modal.show('send-review-modal')
    },
    onHideModal() {
      this.$modal.hide('send-review-modal')
    },
    onChangeDelegationSettings(data, withModal, participantsDone = false) {
      if (withModal) this.$refs.reviewSubmit.preload = true
      patchDelegationSettings(data)
        .finally(() => {
          this.fetchDelegationsSettings()
        })
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Настройки делегации изменены'
          })
          if (participantsDone) this.fetchItems()
          if (withModal) {
            this.fetchItems()
            this.$refs.reviewSubmit.preload = false
            this.onHideModal()
          }
        })
    },
    onRowClick(row) {
      const routeData = this.$router.resolve({name: 'numbers-detail', params: {id: row.row.id}})
      window.open(routeData.href, '_blank')
    },
    convertToMinutes(duration) {
      const minutes = Math.floor(duration / 60).toString().padStart(2, '0')
      const seconds = Math.floor(duration % 60).toString().padStart(2, '0')
      return `${minutes}:${seconds}`
    },
    onSetDelegation() {
      this.delegation_name = this.delegation_options.find(item => item.id === this.user_delegation_id).name
      this.$store.commit('setDelegation', this.user_delegation_id)
      patchUser({ delegation: this.user_delegation_id })
        .then(response => {
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Делегация успешно изменена'
          })
          this.fetchItems()
          this.fetchDelegationsSettings()
          this.$store.commit('setDelegationHash', response.data.delegation_hash)
        })
    },
    onCopyLinkToClipboard() {
      const text = `${process.env.VUE_APP_SITE_URL}members/delegation/${this.$store.state.delegation_hash}`
      navigator.clipboard.writeText(text)
        .then(() => {
          this.$notify({
            type: 'success',
            text: 'Ссылка успешно скопирована'
          })
        })
    },
  }
}
</script>

